import * as Sentry from '@sentry/react';
import { Component, ErrorInfo, ReactNode } from 'react';
import { useFantonTranslation } from '@Global/hooks/i18n';
import { Button } from '@Global/ui-kit';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  private getErrorComponent(componentStack: string): string {
    // Получаем первую строку стека, которая содержит компонент, где произошла ошибка
    const firstLine = componentStack.split('\n')[1] || '';
    const componentMatch = firstLine.match(/at\s+([^\s]+)/);
    return componentMatch ? componentMatch[1] : 'Unknown Component';
  }

  private getRouteInfo() {
    return {
      pathname: window.location.pathname,
      search: window.location.search,
      hash: window.location.hash,
      href: window.location.href,
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const routeInfo = this.getRouteInfo();
    const errorComponent = this.getErrorComponent(errorInfo.componentStack);

    Sentry.withScope((scope) => {
      // Теги для быстрой фильтрации
      scope.setTag('source', 'ErrorBoundary');
      scope.setTag('type', 'react_error_boundary');
      scope.setTag('errorComponent', errorComponent);
      scope.setTag('route', routeInfo.pathname);

      // Детальная информация об ошибке
      scope.setExtra('componentStack', errorInfo.componentStack);
      scope.setExtra('errorName', error.name);
      scope.setExtra('errorMessage', error.message);
      scope.setExtra('errorStack', error.stack);

      // Информация о маршруте
      scope.setContext('RouteInfo', routeInfo);

      // Установка уровня ошибки
      scope.setLevel('error');

      // Уникальный fingerprint для группировки похожих ошибок
      scope.setFingerprint(['error_boundary', errorComponent, routeInfo.pathname, error.name, error.message]);

      Sentry.captureException(error);
    });
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorView />;
    }

    return this.props.children;
  }
}

const ErrorView = () => {
  const t = useFantonTranslation();

  return (
    <div className="tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-bg-surface-primary">
      <div className="tw-text-center tw-p-8 tw-max-w-lg tw-mx-auto">
        <h1 className="tw-text-4xl tw-font-bold tw-text-gray-100 tw-mb-4">{t('Oops! Something went wrong')}</h1>
        <p className="tw-text-gray-300 tw-mb-8">
          {t('We apologize for the inconvenience. Our team has been notified and is working to fix the issue.')}
        </p>
        <Button onClick={() => window.location.reload()}>{t('Refresh Page')}</Button>
      </div>
    </div>
  );
};
